.floating-button-container {
    position: fixed;
    top: 10px;
    /* right: 20px; */
    left: 45px;
    z-index: 1000;
    cursor: move;
}

.floating-button {
    background-color: #dabf38;
    color: rgb(0, 0, 0);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    padding: 10px;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    animation: pulse 2s infinite, glow 3s infinite alternate;
    transition: transform 0.3s ease;
}

.floating-button:hover {
    transform: scale(1.1);
}

.floating-button span {
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
}

.roulette-wheel {
    width: 50px;
    height: 50px;
    animation: spin 10s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes glow {
    from {
        box-shadow: 0 0 5px #dabf38, 0 0 10px #dabf38, 0 0 15px #dabf38;
    }

    to {
        box-shadow: 0 0 10px #dabf38, 0 0 20px #dabf38, 0 0 30px #dabf38;
    }
}