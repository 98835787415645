.bottom-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #2D0A13;
    border-top: 1px solid #bf4dce;
    z-index: 1000;
    padding: 5px 0;
    /* Reducir el padding para menos height */
}

.bottom-nav a {
    flex: 1;
    text-align: center;
    color: #fff;
    /* Cambiar el color del texto a blanco para mejor contraste */
    text-decoration: none;
    font-size: 12px;
    /* Ajustar el tamaño de la fuente */
}

.bottom-nav a.active {
    color: orange !important;
    /* Color para el enlace activo */
}

.bottom-nav .icon {
    display: block;
    margin: 0 auto;
    /* Centramos el icono */
    font-size: 24px;
    /* Ajustar el tamaño del icono */
}

.bottom-nav span {
    display: block;
    margin-top: 2px;
    /* Ajustar la separación entre icono y texto */
    font-size: 10px;
    /* Tamaño de texto más pequeño para ahorrar espacio */
}